.separator {
  width: 100%;
  height: 30px;
  margin-bottom: 25px;
}

.green {
  background-color: #41e249;
}

.black {
  background-color: #1b1b1b;
}
