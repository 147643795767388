.about-wrapper{
padding: 0 6rem;
}

.about-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;
}

.about-container:nth-child(odd){
    flex-direction: row-reverse;
}

.about-container img{
    width: 400px;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 1px black;
}

.about-container div{
    padding: 0 2rem;
    width: 800px;
}

.about-container div h1{
    font-size: 2.5rem;
    font-family: var(--secondary_font);
    color: #41e249;
}

.about-container div p{
    font-size: 1.2rem;
    font-family: var(--primary_font);
    font-weight: 600;
}

@media only screen and (max-width: 990px) {

    .about-wrapper{
        padding: 0 4rem;
    }

    .about-container img{
        width: 300px;
    }

    .about-container div{
        padding: 0 2rem;
    }

    .about-container div h1{
        font-size: 1.5rem;
    }
    
    .about-container div p{
        font-size: 1rem;
    }
  }

@media only screen and (max-width: 800px) {

    .about-wrapper{
        padding: 0 4rem;
    }

    .about-container img{
        width: 250px;
    }

    .about-container div h1{
        font-size: 1.3rem;
    }
    
    .about-container div p{
        font-size: 0.9rem;
    }
  }
  
@media only screen and (max-width: 700px) {
    .about-container{
        flex-direction: column;
        max-width: 400px;
        margin: 0 auto 2rem;
    }

    .about-container:nth-child(odd){
        flex-direction: column;
    }

    .about-container img{
        width: 100%;
        height: 250px;
    }

    .about-container div{
        width: fit-content;
        padding: 0;
        margin-top: 1.5rem;
    }
  }
